<template>
    <b-modal :ref="modalRef" hide-footer @hidden="destroyModal" title="Brouillons">
        <b-row v-for="(brouillon, index) in brouillons" :key="'brouillon_' + index">
            <b-col v-if="index" cols="10" offset="1">
                <hr/>
            </b-col>
            <b-col cols="9">
                {{ formatDate(brouillon.date, 'Do MMMM YYYY [à] H[h]mm') }}
            </b-col>
            <b-col cols="3">
                <b-button variant="info" @click="loadBrouillon(brouillon)" size="sm" pill>
                    <font-awesome-icon icon="download"/>
                </b-button>
                <b-button variant="danger" @click="deleteBrouillon(brouillon)" size="sm" pill>
                    <font-awesome-icon :icon="['far', 'trash-alt']"/>
                </b-button>
            </b-col>
        </b-row>

        <div class="text-center mt-3">
            <b-button @click="hideModal">
                Fermer
            </b-button>
        </div>
    </b-modal>
</template>

<script>
    import alert                  from '@/util/alert';
    import {apiPath}              from '@/util/http';
    import {momentDatesBrouillon} from '@/util/wiki';
    import {formatDate}           from '@/util/date';
    import modalMixin             from '@/mixin/modalMixin';

    export default {
        name: "ListBrouillonArticleModal",
        mixins: [modalMixin],
        props: {
            articleId: {
                type: Number,
                required: true
            },
            onSelect: {
                type: Function,
                required: true
            }
        },
        data: () => ({
            modalRef: 'ListBrouillonArticleModal',
            brouillons: []
        }),
        methods: {
            formatDate,
            loadData() {
                alert.loading()
                    .then(() => this.axios.get(apiPath('wiki_brouillon_list', {
                        article: this.articleId
                    })))
                    .then(response => this.brouillons = momentDatesBrouillon(response.data))
                    .catch(() => this.$toaster.error('Impossible de charger la liste des brouillons'))
                    .finally(alert.stopLoading);
            },
            loadBrouillon(brouillon) {
                alert.loading()
                    .then(() => this.axios.get(apiPath('wiki_brouillon_full', {brouillon: brouillon.id})))
                    .then(response => {
                        this.onSelect(momentDatesBrouillon(response.data));
                        this.hideModal();
                    })
                    .catch(() => this.$toaster.error('Impossible de charger ce brouillon'))
                    .finally(alert.stopLoading);
            },
            deleteBrouillon(brouillon) {
                if (confirm('Supprimer ce brouillon ?'))
                    alert.loading()
                        .then(() => this.axios.delete(apiPath('wiki_brouillon_delete', {brouillon: brouillon.id})))
                        .then(() => this.loadData())
                        .catch(() => this.$toaster.error('Erreur lors de la suppression du brouillon'))
                        .finally(alert.stopLoading());
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>
